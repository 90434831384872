body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.countdown-header {
  font-size: 40px;
  text-align: center;
}

.countdown-clock {
  font-size: 24px;
  text-align: center;
}
